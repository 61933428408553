import React, { useState, useEffect } from "react";
import Remarks from "new_component/Quotation/Remarks";
import { useQuoteList } from "utils/contexts/quoteListContext";
import RenderTable from "./RenderTable";
import axios from "axios";

const TestQuoteModal = ({ data, onclose, showModal }: any) => {
  const [freightBuyRate, setFreightBuyRate] = useState(data.totalPrice);
  const [freightSellRate, setFreightSellRate] = useState<number>(
    data.totalPrice
  );
  const [totalBuyRate, setTotalBuyRate] = useState(data.totalPrice);
  const [totalSellRate, setTotalSellRate] = useState(data.totalPrice);
  const [showRemarks, setShowRemarks] = useState(false);
  const [editID, setEditID] = useState(-1);

  // render table component

  const { quoteList, addLegs } = useQuoteList();

  const handleOnClose = () => {
    onclose();
  };
  const sum = (arr: number[], initialvalue: number) => {
    if (arr.length > 0) {
      return arr.reduce((pv: number, cv: number) => {
        return pv + cv;
      }, initialvalue);
    } else {
      return initialvalue;
    }
  };

  useEffect(() => {
    if (quoteList.length > 0) {
      const index = quoteList.findIndex((item: any) => {
        return item?.id === data.id;
      });
      setEditID(index);
    }
    let totalSellArray = 0;
    let totalBuyArray = 0;
    if (editID !== -1) {
      console.log("ABC", quoteList[editID].customLegs);
      if (quoteList[editID].customLegs) {
        const arraySellList = quoteList[editID].customLegs.map((item: any) => {
          console.log("Array", item.customCharge);
          return sum(
            item.customCharge.map((val: any) => val.netSellRate),
            0
          );
        });
        console.log("Array List", sum(arraySellList, 0));
        totalSellArray = sum(arraySellList, 0);

        const arrayBuyList = quoteList[editID].customLegs.map((item: any) => {
          console.log("Array", item.customCharge);
          return sum(
            item.customCharge.map((val: any) => val.netBuyRate),
            0
          );
        });
        console.log("Array List", sum(arrayBuyList, 0));
        totalBuyArray = sum(arrayBuyList, 0);
      }

      setTotalSellRate(
        sum(
          quoteList[editID].freightCharges.map(
            (item: any) => item.netSellRate || item.totalAmountInUSD
          ),
          sum(
            quoteList[editID].originCharges.map(
              (item: any) => item.netSellRate || item.totalAmountInUSD
            ),
            sum(
              quoteList[editID].destinationCharges.map(
                (item: any) => item.netSellRate || item.totalAmountInUSD
              ),
              totalSellArray
            )
          )
        )
      );

      setTotalBuyRate(
        sum(
          quoteList[editID].freightCharges.map(
            (item: any) => item.netBuyRate || item.totalAmountInUSD
          ),
          sum(
            quoteList[editID].originCharges.map(
              (item: any) => item.netBuyRate || item.totalAmountInUSD
            ),
            sum(
              quoteList[editID].destinationCharges.map(
                (item: any) => item.netBuyRate || item.totalAmountInUSD
              ),
              totalBuyArray
            )
          )
        )
      );
    }
  }, [quoteList]);

  // hiding background scrollbar when modal open
  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showModal]);

  const renderLocalQuotes = () => {
    console.log("Inside Local Quotes", quoteList[editID].originCharges[0]);

    return (
      <div>
        {
          <RenderTable
            chargeName="Freight"
            charges={quoteList[editID].freightCharges}
            legId="0"
            quoteId={data.id}
          />
        }
        {quoteList[editID].originCharges.length > 0 && (
          <RenderTable
            chargeName="Origin"
            charges={quoteList[editID].originCharges}
            legId="1"
            quoteId={data.id}
          />
        )}
        {quoteList[editID].destinationCharges.length > 0 && (
          <RenderTable
            chargeName="Destination"
            charges={quoteList[editID].destinationCharges}
            legId="2"
            quoteId={data.id}
          />
        )}
        {quoteList[editID]?.customLegs?.map((item: any, index: number) => {
          return (
            <RenderTable
              chargeName={item.legName}
              charges={item.customCharge}
              legId={item.id}
              quoteId={data.id}
            />
          );
        })}
        <div
          className="py-4 pb-8 px-4 cursor-pointer  "
          onClick={() => addLegs(data.id)}
        >
          <button className=" border-2 px-4 py-2 rounded-2xl cursor-pointer border-indigo-500">
            {" "}
            +Add Legs
          </button>
        </div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="flex relative h-[75%] flex-col align-center bg-white thinScrollbar overflow-x-auto  dark:bg-neutral-700">
        <div className="flex justify-center  my-4 px-3 w-full">
          <button
            onClick={() => setShowRemarks(false)}
            className={`p-1 w-[200px] rounded-l-full border border-r-1 ${
              showRemarks ? "" : "text-white bg-indigo-500"
            }`}
          >
            Quotation Details
          </button>
          <button
            onClick={() => setShowRemarks(true)}
            className={`p-1 w-[200px] rounded-r-full border border-r-1 ${
              showRemarks ? "text-white bg-indigo-500" : ""
            }`}
          >
            Remarks
          </button>
        </div>
        {/* TODO translate animation */}
        {showRemarks ? (
          <div
            className={`top-0 left-0 w-full  transition-all duration-[600ms] ${
              showRemarks ? "translate-x-0" : "translate-x-full "
            }`}
            data-taos-offset="400"
          >
            <Remarks data={data} quote={quoteList[editID]} />
          </div>
        ) : (
          <>{quoteList[editID] && renderLocalQuotes()}</>
        )}
      </div>
    );
  };

  const renderBottom = () => {
    // Create PDF
    const generateQuote = () => {
      // console.log("INDIVIDUAL QUOTE", quoteList[editID]);

      // TESTING

      axios
        .post("https://apis.transpost.co/api/rates/pdf", quoteList[editID], {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          console.log(
            "LINK",
            `https://apis.transpost.co/storage/quotes/${response.data}`
          );
          const link = document.createElement("a");

          link.href = `https://apis.transpost.co/storage/quotes/${response.data}`;
          link.setAttribute("download", `${link.href}`);
          document.body.appendChild(link);
          window.open(
            `https://apis.transpost.co/storage/quotes/${response.data}`
          );
          // link.click();
        });
      onclose();
    };

    return (
      <div className=" h-auto w-full flex border-t z-9999 inset-x-0 bottom-0 p-4 justify-between bg-white overscroll-none dark:bg-neutral-800">
        <div className="flex flex-col pl-2 ">
          <span>BUY RATE</span>
          <span>{totalBuyRate.toFixed(2)}</span>
        </div>
        <div className="flex flex-col border-l-2 pl-5">
          <span>SELL RATE</span>
          <span>{totalSellRate.toFixed(2)}</span>
        </div>
        <div className="border-l-2 pl-5">
          You Earn USD{" "}
          <span className=" font-semibold">
            {(totalSellRate - totalBuyRate).toFixed(2)}{" "}
          </span>
          <span className="text-[green] font-semibold">
            {" "}
            {(((totalSellRate - totalBuyRate) * 100) / totalBuyRate).toFixed(
              2
            )}{" "}
            %{" "}
          </span>{" "}
          on this rate
        </div>
        <button
          onClick={generateQuote}
          className="order-last bg-blue p-2 px-6 border border-1 rounded-2xl bg-indigo-500 text-white"
        >
          Generate PDF
        </button>
      </div>
    );
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className=" p-10 fixed z-max  inset-0 bg-neutral-200 bg-opacity-10  backdrop-blur-sm border rounded dark:border-neutral-800 ">
      <div className="flex relative py-5 h-full flex-col text-xs sm:text-base  align-center bg-white border rounded-lg  dark:border-neutral-600 dark:bg-neutral-700">
        <div className="flex h[15%] order-first justify-between border-b p-4 text-2xl dark:bg-neutral-800 ">
          <span>Details</span>
          <button onClick={handleOnClose} className="order-last">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {renderTop()}
        {renderBottom()}
      </div>
    </div>
  );
};

export default TestQuoteModal;
