import React, { useState, useEffect } from "react";
import { useQuoteList } from "utils/contexts/quoteListContext";
import AdditionalCharges from "./AdditionalCharges";
import { TrashIcon } from "@heroicons/react/20/solid";

const RenderTable = ({ chargeName, charges, legId, quoteId }: any) => {
  const [showRemarks, setShowRemarks] = useState(false);
  const [total, setTotal] = useState(0);
  const [legName, setLegName] = useState(chargeName);
  const [IsDeleteVisible,setIsDeleteVisible] = useState(false);

  const { quoteList, deleteLegs } = useQuoteList();

  const sum = (arr: number[], initialvalue: number) => {
    if (arr.length > 0) {
      return arr.reduce((pv: number, cv: number) => {
        return pv + cv;
      }, initialvalue);
    } else {
      return initialvalue;
    }
  };

  useEffect(() => {

    setTotal(
      sum(
        charges.map((item: any) => item.netSellRate || item.totalAmountInUSD),
        0
      )
    );
  }, [quoteList]);

  return (
    <div
      className={`top-0 left-0 max-w-full transiton-all pb-10 thinScrollbar  overflow-y-auto inline-block ease-in-out duration-300 ${
        showRemarks ? "translate-x-full " : "translate-x-0"
      }`}
    >
      <div className="flex w-full bg-neutral-200 mx-5 my-3 mt-5 w-auto">
        <span className=" px-4 items-center p-2">
          <input
            className="rounded-lg p-2 font-semibold text-lg"
            placeholder="Leg Name"
            value={legName}
            onChange={(e) => setLegName(e.target.value)}
          />
        </span>
        <span className="flex ml-auto px-3 self-center gap-2 ">
          <span
            className={`flex self-center cursor-pointer text-[red]  justify-self-end ${
              legName === "Freight" || "Destination" || "Origin" ? "hidden" : ""
            }`}
            onClick={() => deleteLegs(quoteId, legId)}
          >
            {" "}
            <TrashIcon className="h-5 mr-1" />
            Delete Leg
          </span>
          <span className="self-center">Subtotal</span>
          <span className="flex px-4 bg-white rounded-lg items-center ml-auto border-r-2">
            {total.toFixed(2)}
            <select className="pr-15 border-0 ml-auto text-sm lg:text-base focus:ring-0 focus:outline-none dark:bg-transparent">
              <option className="text-sm lg:text-base">USD</option>
            </select>
          </span>
        </span>
      </div>{" "}
      <div className="grid mx-3 mb-0 w-auto   grid-cols-10 font-semibold thinScrollbar  overscroll-y-auto">
        <div className=" p-[2rem] col-start-1 col-end-3 border-b border-neutral-500 bg-neutral-100 row-span-2 flex px-3 items-center">
          Charges
        </div>
        <div className="flex px-3 items-center bg-neutral-100 border-b border-neutral-500 row-span-2 ">
          Basis
        </div>
        <div className="flex px-3 items-center bg-neutral-100 border-b border-neutral-500 row-span-2">
          Equipment Type
        </div>
        <div className="flex px-3 items-center bg-neutral-100 border-b border-neutral-500 row-span-2">
          Quantity
        </div>
        <div className="flex pl-3 items-center bg-neutral-200 border-b border-neutral-500 col-span-2">
          Buy Rates
        </div>
        <div className="flex pl-3 items-center bg-neutral-100 border-b border-neutral-500 col-span-2">
          Sell Rates
        </div>
        <div className="bg-neutral-100 row-span-2 border-b border-neutral-500"></div>
        <div className="flex px-3 items-center bg-neutral-200 border-b border-neutral-500">
          Unit Price
        </div>
        <div className="flex px-3 items-center bg-neutral-200 border-b border-neutral-500">
          Amount
        </div>
        <div className="flex px-3 items-center bg-neutral-100 border-b border-neutral-500">
          Unit Price
        </div>
        <div className="flex px-3 items-center bg-neutral-100 border-b border-neutral-500">
          Amount
        </div>
      </div>
      {charges.map((item: any, index: number) => {
        return (
          <AdditionalCharges
            key={item.chargeId}
            legName={legName}
            item={item}
            quoteId={quoteId}
            chargeId={item.chargeId}
            legId={legId}
          />
        );
      })}
    </div>
  );
};

export default RenderTable;
