import { combineReducers } from "redux";
import rateListReducer from "./rateList/rateListReducer";
import transportListReducer from "./transportRateList/transportListReducer";
import { userReducer } from "./user/userReducer";

const rootReducer = combineReducers({
  data: rateListReducer,
  user: userReducer,
  transportData: transportListReducer
});

export default rootReducer;
