/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Feb 28 2023
 * Updated on Tue Feb 28 2023
 * File Content:
 * *================*
 * *================*
 * Transportation Search Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Apr 04 2023
 * Updated at 4:58:50 PM
 *
 */



/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 5:59:48 PM
 *
 */


// React Default imports
import { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Custom Components
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
// import { OceanRateList } from "./OceanRateList";
import { TransportationRateType, filterTypes } from "./transportationTypes";

// Libraries, Contexts & Helpers
import { Helmet } from "react-helmet";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import useQueryParams from "hooks/useQueryParams";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { regularAPI, secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";
import { getMinMax } from "utils/helpers/minMax";
import TransportationRateListFilter from "./TransportationRateListFilter";
import { TransportationRateList } from "./TransportationRateList";
import { useQueryState } from "hooks/useQueryState";
import FilterSkeleton from "components/SkeletonComponents/FilterSkeleton";
import { getRequest, secureGetRequest } from "utils/helpers/apiVerbs";
import { useUserAuth } from "utils/contexts/userContext";
import girlSearch from "../../images/search-girl1.svg"


export interface TransportationSearchPageProps {
  className?: string;
}

const TransportationSearchPage: FC<TransportationSearchPageProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const history = useHistory();

  const [rates, setRates] = useState<TransportationRateType[]>([]);
  const [filteredRates, setFilteredRates] =
    useState<TransportationRateType[]>(rates);

  const [apiCalled , setApiCalled] = useState(false);

  const [filters, setFilters] = useState<filterTypes>({
    rangePrice: [],
    selectedPriceRange: [],
  });

  const [transportationType, setTransportationType] = useQueryState(
    "transportation_type"
  );
  const [loadingPort, setLoadingPort] = useQueryState("loading_port");
  const [factoryCity, setFactoryCity] = useQueryState("factory_city");
  const [size, setSize] = useQueryState("size");
  const [cargoWeight, setCargoWeight] = useQueryState("cargo_weight");

  const { setIsLoading, setError } = useEventContext();

  const [showLoading, setShowLoading] = useState(false)

  const getTransportationRates = async () => {

    setShowLoading(true);
    setApiCalled(true);
    try {
      const transportationRates: any = await getRequest(apiPaths.transport.rates.transport_select, {
        transportation_type: transportationType,
        loading_port: loadingPort,
        factory_city: factoryCity,
        size: size,
        cargo_weight: cargoWeight,
      })
      setRates(transportationRates.data.data);
      setFilters(getFilterRange(transportationRates.data.data));
      setShowLoading(false);
    } catch (error: any) {
      setError({ ...error.response.data, show: true })
      setShowLoading(false);
      setFilters(getFilterRange([]));
    }

  };


  //   Filtering using recursion
  const getFilterRange = (
    data: [],
    priceRange: string[] = [],
    index: number = 0,
    minMaxPriceRange: number[] = []
  ): any => {
    if (index === data.length - 1)
      return {
        ...filters,
        priceRange,
        selectedPriceRange: getMinMax(priceRange),
      };

    const freightCharges = data[index]["rates"];

    priceRange.push(freightCharges);
    index += 1;

    return getFilterRange(data, priceRange, index, minMaxPriceRange);
  };

  const filterRatesChangeHandler = (filteredRate: number[]): void => {
    setFilters({ ...filters, selectedPriceRange: filteredRate });

    const allRates = [...rates];

    const allFilteredRates = allRates?.filter(
      (rate: TransportationRateType) => {
        const freightCharge = rate.rates!;

        if (
          filteredRate[0] <= freightCharge &&
          freightCharge <= filteredRate[1]
        )
          return rate;
      }
    );

    setFilteredRates(allFilteredRates);
  };

  const filterChangeHandler = (data: number[], type: string): void => {
    switch (type) {
      case "price":
        setFilters({ ...filters, selectedPriceRange: data });
        break;
    }

    const allRates = [...rates];

    const allFilteredRates = allRates?.filter(
      (rate: TransportationRateType, index: number) => {
        const freightCharge = rate.rates!;

        if (
          filters.selectedPriceRange![0] <= freightCharge &&
          freightCharge <= filters.selectedPriceRange![1]
        )
          return rate;
      }
    );

    setFilteredRates(allFilteredRates);
  };

  useEffect(() => {
    setFilteredRates(rates);
  }, [rates]);

  useEffect(() => {
    if (transportationType && loadingPort && factoryCity && cargoWeight && size) {

      getTransportationRates()
      setFilteredRates([])
      setRates([])
      setFilters({
        rangePrice: [],
        selectedPriceRange: [],
      })
      
    }
  }, [transportationType, loadingPort, factoryCity, cargoWeight, size])

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Transpost | Transportation Search Rates</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-32">
        <div className="flex flex-col  lg:flex-row -mt-25 lg:items-center">

          <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 xl:pr-14 lg:mr-10 xl:mr-0">
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
              <div className="font-Montserrat">
                Transportation Rates Search.
              </div>
            </h2>
            <p className={"text-[#808080]"}>We partner with trusted transportation companies to ensure that you receive the highest level of service and reliability at the most competitive rates. Whether you need transportation for personal or business use, our platform makes it easy to find the right option to meet your needs</p>
            {/* </div> */}

          </div>
          <div className=" hidden md:flex  flex-grow w-full justify-center">
            <img className="w-3/5 object-fill " src={girlSearch} alt="hero" />

          </div>
        </div>
        <div
          className={`nc-SectionHeroArchivePage flex flex-col relative mt-40 mb-40 pt-10 pb-10 lg:pb-1 lg:pt-16`}
          data-nc-id="SectionHeroArchivePage"
        >
          <div className="relative ">
            <div className="z-max xl:-mt-40 xl:-mt-18 w-full">
              <HeroSearchForm
                currentPage="Transportation"
                currentTab="Transportation"
                autoFillForm={{
                  assign: "Transportation",
                  page: "transportationpage",
                  feilds: {
                    transportation_type: transportationType,
                    factory_city: factoryCity,
                    loading_port: loadingPort,
                    size: size,
                    cargo_weight: cargoWeight,
                  },
                }}
              />
            </div>

            <div
              className={`nc-SectionGridFilterCard ${className}`}
              data-nc-id="SectionGridFilterCard"
            >
              <div className="mb-8 mt-8 lg:mb-8">
                {showLoading && <FilterSkeleton />}


                {/* {filters.rangePrice?.length === 0 && filteredRates.length === 0 ?

                  <></> :

                  <TransportationRateListFilter
                    filters={filters}
                    filterChangeHandler={(data: any, type: string) =>
                      filterChangeHandler(data, type)
                    }
                    filterRatesChangeHandler={(fileterdRates: any) =>
                      filterRatesChangeHandler(fileterdRates)
                    }
                    clearRatesChangeHandler={() => setFilteredRates(rates)}
                  />} */}
              </div>
            </div>
            <TransportationRateList rateList={filteredRates!} showLoading={showLoading} apiCalled={apiCalled}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportationSearchPage;
