/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Wed Feb 15 2023
 * Updated on Wed Feb 15 2023
 * File Content:
 * *================*
 * *================*
 * Cargo Info Form component
 * *================*
 * *================*
 */

import React from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

const CargoInfoForm = ({ showCargoInfo, register }: any) => {
  return (
    <div
      className={`overflow-hidden transition-[max-height] duration-600 ease-in-out ${
        showCargoInfo ? "max-h-full" : "max-h-0"
      } grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 `}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Commodity
        </span>
        <Input
          type="text"
          placeholder="Enter Commodity"
          className="mt-1"
          {...register("commodity")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Transit Mode
        </span>
        <Input
          type="text"
          placeholder="Transit Mode"
          className="mt-1"
          {...register("transit_mode")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Coverage Start Date
        </span>
        <Input
          type="date"
          placeholder=""
          className="mt-1"
          {...register("coverage_start_date")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">Origin</span>
        <Input
          type="text"
          placeholder="From"
          className="mt-1"
          {...register("location_from")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Destination
        </span>
        <Input
          type="text"
          placeholder="To"
          className="mt-1"
          {...register("location_to")}
        />
      </label>
      <label className="block sm:col-span-3">
        <span className="text-neutral-800 dark:text-neutral-200">
          Cargo Description
        </span>
        <Textarea
          placeholder="Cargo Description"
          {...register("cargo_description")}
        />
      </label>
    </div>
  );
};

export default CargoInfoForm;
