import React, { FC, ReactNode, useEffect, useState } from "react";
import imagePng from "images/Hero-illustration-main-1.png";

import hero7 from "images/transpost images/heros/Home/hero.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import RecentSearches from "new_component/RecentSearches/RecentSearches";
import VideoContainer from "new_component/VideoContainer/VideoContainer";
import NcModal from "shared/NcModal/NcModal";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import CargoDetailsModal from "new_component/CargoDetailsModal/CargoDetailsModal";
import { useEventContext } from "utils/contexts/eventContext";
import SectionGridFilterCard from "containers/ListingFlightsPage/SectionGridFilterCard";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Cargo Tracker" | "Air" | "Transportation" | "Ocean";
  currentTab: SearchTab;
  rightImage?: string;
}

export interface UserDetailsProps {
  KYC?: boolean;
  customer: {
    companyName?: string;
    contact_person?: string;
    email?: string;
    ID?: number | string;
    name?: string;
    phone?: string;
    customer_type?: string;
    company_logo: null | string;
    EnterpriseType: null | string;
    gst_certificate: null | string;
    pan_card: null | string;
    gst_number: string | null;
    pan_number: string | null;
    kyc: string;
    profile_picture: null | string;
  };
  message?: string;
  status?: string;
  token?: string;
  storemart_token?: {
    encryptpass: string;
  };
  storemart_key?: {
    encryptpass: string;
  };
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = hero7,
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative pb-10 lg:pb-1`}
      data-nc-id="SectionHeroArchivePage"
    >
      <CargoDetailsModal />
      <div className="flex flex-col lg:flex-row  px-[12rem] lg:pb-[5.3rem] lg:items-center">
        <div className=" hidden lg:flex  flex-grow ">
          <img className="w-full pb-10 object-fill" src={rightImage} alt="hero" />
        </div>
      </div>
      <div className="relative ">
        <div className="flow-root w-full md:absolute z-10">
          {/* for moving the search bar up and down */}
          <div className="z-max lg:-mt-40 xl:-mt-18 w-full">
            <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
          </div>
        </div>
        <div className=" mt-12 pt-20">
          {/* TODO redux call for recent searches */}
   
          <SectionGridFilterCard
            from_port=""
            to_port=""
            token=""
            sl_date=""
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
