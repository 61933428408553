import { Page } from "./types";
import Dashboard from "new_component/Dashboard";
import UserDashboard from "new_component/UserDashboard";
import CustomClearancePage from "containers/CustomClearanceSearchPage/CustomClearancePage";
import AllBookings from "new_component/UserDashboard/AllBookings";
import AllQuotes from "new_component/UserDashboard/AllQuotes";
import RecentSearches from "new_component/UserDashboard/RecentSearches";
import ProfilePage from "containers/ProfilePage";

const privatePageList: Page[] = [
    { path: "/dashboard", exact: true, component: UserDashboard },
    { path: "/all-bookings", exact: true, component: AllBookings },
    { path: "/all-quotes", exact: true, component: AllQuotes },
    { path: "/recent-search", exact: true,component: RecentSearches },

    { path: "/user/bookings", exact: true, component: Dashboard },
    { path: "/profile", exact: true, component: ProfilePage },

]

export default privatePageList;